import React, { useContext, useLayoutEffect, useRef, useState } from 'react';
import { Box, Button, Stack, Typography } from '@mui/material';
import { MyContext } from '../../../router/router';
import backgroundImage from  '../../../img/background.png'
import TitleColumn from '../../Title/Column';
import {BaseButton, BaseGrid, ObserverStack} from "reidea-common"
import { UseQueryResult } from 'react-query';
import { useGetProjects } from '../../API/projects';
import { simple } from '../../../interface/company';
import { useGetCompanies } from '../../API/companies';


const Companies: React.FC = () => {

    // Mycontextからプログラム全体で共有する変数を持ってこれる。
    const { subTitleFontSize, contentRatio, isXS, isSmall} = useContext(MyContext);

    // モバイル判定
    const _isMobile = (isXS || isSmall)

    // コンポーネントが画面内に入ったらisViewedがtrueになる。isOneTime={true}となっているときは、trueになったっきりで戻らない。
    const [isViewed, setIsViewed] = useState<boolean>(false)

    const buttonHeight = (subTitleFontSize-4)*2+'px'
    const buttonFontSize = subTitleFontSize-12;

    const onClick = (id:string) => {
        window.location.href = "/company?companyID="+id
    }

    const { data: companies, queryKey:companies_queryKey, isLoading: isLoading_companies } : UseQueryResult<any[], Error> & { queryKey: string } = useGetCompanies("companies", null);

    return (
        <Stack id={"about"} position={"relative"} direction={"column"} width={"100%"} minWidth={"100%"} bgcolor={"#F5FBFE"} alignItems={"center"} py={6} pb={26}>
            <Stack component="img" position={"absolute"} zIndex={0} top={0} src={backgroundImage} width={"100%"} height={"100%"} sx={{ opacity: 0.8, objectFit:"cover", color:"white"}}/>

            <TitleColumn title='会社一覧' title_en='COMPANIES' fontSize={subTitleFontSize+5}/>
            <Stack width={contentRatio*100+"%"} direction={"row"} mt={3} alignItems={"flex-start"} justifyContent={"space-between"} >
                <ObserverStack width={"100%"} alignItems={"center"} isOneTime={true} setObserved={setIsViewed}>
                    <BaseGrid enableAnimation={true} animation={isViewed} animationDuration={1} animationDelay={0.2} animationInitialDisplay={false} animationOffsetY={10} 
                        height={(subTitleFontSize-4)*3} cols={1} spacingX={1} spacingY={2} items={
                        (companies ?? []).map((company:simple, index:number) => (
                            <BaseButton onClick={()=>{onClick(company.company_id)}}
                                color='#383E86' border='1px solid #383E86' 
                                backgroundColor='white' selectedBackGroundColor='lightgrey' 
                                text={company.name} height={buttonHeight} fontSize={buttonFontSize} bold />
                        ))
                    }/>
                </ObserverStack>
            </Stack>
        </Stack>
    );
};

export default Companies;