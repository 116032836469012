
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { UseMutationResult, UseQueryResult, useMutation, useQuery } from 'react-query';

interface PutJudgePayload {
    newValues: { [key: string]: string | number | undefined };
}


// ---------- simple ------------

// GET /chat/type/to_user_id or query
export const useGetProjects = <T,>(query_key: string, project_id: string | null, ): UseQueryResult<T, Error> & { queryKey: string } => {
    // ideas/ simple or medium (/) idea_id or (?) query
    const API_END_POINT_PASS = `/projects/${project_id ? "?project_id="+project_id : ""}`
    const QUERY_KEY = query_key;
    return {
      ...useQuery([QUERY_KEY, project_id], () => fetch(process.env.REACT_APP_API_URL+API_END_POINT_PASS,{credentials: 'include'}).then(res =>res.json()),
    {enabled: query_key !== ""}),
      queryKey:QUERY_KEY,
    };
}

// PUT /like
export const usePutProject = (): UseMutationResult<void, Error, PutJudgePayload> => {
    return useMutation(async ({ newValues }: PutJudgePayload) => {
      // newValuesで指定されているFile系の値を送信用に変換したりする
      const formData = new FormData();

      // "file" という名前のキーを除外した新しいオブジェクトを作成
      const updateFields: { [key: string]: any } = {};
      for(let key in newValues){
          if(newValues[key] !== undefined){
            // その他変更したいカラムはupdateFieldsにぶち込む
            updateFields[key] = newValues[key];
          }
      }
      formData.append("updateFields", JSON.stringify(updateFields))

      const response = await axios.put(`${process.env.REACT_APP_API_URL}/projects/`, updateFields, {withCredentials:true});
  
      if (response.status !== 200) {
        throw new Error('Failed to delete idea');
      }
  
      return response.data;
    });
  };
  
// DELETE /projects/project_id
export const useDeleteProject = (): UseMutationResult<void, Error, string> => {
  return useMutation(async (project_id: string) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/projects/${project_id}`, {
      method: 'DELETE',
      credentials: "include",
    });

    if (!response.ok) {
      throw new Error('Failed to delete idea');
    }
  });
};
