
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { UseMutationResult, UseQueryResult, useMutation, useQuery } from 'react-query';

interface PutJudgePayload {
    newValues: { [key: string]: string | number | undefined };
}


// ---------- simple ------------

// GET /chat/type/to_user_id or query
export const useGetSections = <T,>(query_key: string, query?:string,): UseQueryResult<T, Error> & { queryKey: string } => {
    const API_END_POINT_PASS = `/sections${query ? "?"+query : ""}`
    const QUERY_KEY = query_key;
    return {
      ...useQuery([QUERY_KEY, query], () => fetch(process.env.REACT_APP_API_URL+API_END_POINT_PASS,{credentials: 'include'}).then(res =>res.json()),
    {enabled: query_key !== ""}),
      queryKey:QUERY_KEY,
    };
}

// PUT /like
export const usePutSection = (): UseMutationResult<void, Error, PutJudgePayload> => {
    return useMutation(async ({ newValues }: PutJudgePayload) => {
      // newValuesで指定されているFile系の値を送信用に変換したりする
      const formData = new FormData();

      // "file" という名前のキーを除外した新しいオブジェクトを作成
      const updateFields: { [key: string]: any } = {};
      for(let key in newValues){
          if(newValues[key] !== undefined){
            // その他変更したいカラムはupdateFieldsにぶち込む
            updateFields[key] = newValues[key];
          }
      }
      formData.append("updateFields", JSON.stringify(updateFields))

      const response = await axios.put(`${process.env.REACT_APP_API_URL}/sections/`, updateFields, {withCredentials:true});
  
      if (response.status !== 200) {
        throw new Error('Failed to delete idea');
      }
  
      return response.data;
    });
  };
  
// DELETE /sections/section_id
export const useDeleteSection = (): UseMutationResult<void, Error, string> => {
  return useMutation(async (section_id: string) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/sections/${section_id}`, {
      method: 'DELETE',
      credentials: "include",
    });

    if (!response.ok) {
      throw new Error('Failed to delete idea');
    }
  });
};
