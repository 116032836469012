import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { FormControl,SelectChangeEvent, InputLabel, Select, MenuItem,MenuProps, Grid, styled, Paper, IconButton, Typography, Button, Stack, TextField, Card, Avatar } from '@mui/material';
import { Dispatch, SetStateAction } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { TextareaAutosize } from '@mui/material';
import { makeStyles } from '@mui/material';
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';



interface TagsProps {
    title?: string;
    labels: string[];
    values?: string[]
    default_lables?: string[]
    sort_value: string;
    ids?: string[];
    setSortValue?: Dispatch<SetStateAction<string>> | Dispatch<SetStateAction<string | null>>;
    onSelect?: (value:string) => void;

    isMobile?: boolean;
    ratio? :number;
    minWidth?: number
    variant?:  "standard" | "outlined" | "filled" | undefined;
    borderRadius?: number;
    bold?: boolean;
}

const FlexSortSelector: React.FC<TagsProps> = ({onSelect,  title, labels, values, default_lables, sort_value, setSortValue, ratio, ids, isMobile, minWidth, variant, borderRadius, bold }) => {

    // カスタムスタイルのPaperコンポーネント
    const menuProps = {
        PaperProps: {
            sx: {
                borderRadius: borderRadius ?? 15,
                minWidth: `${(minWidth ?? 140) * (ratio ? ratio : 1)}px`,
                marginTop: '10px',
            },
        },
    };

    return(
        <FormControl variant={variant ?? "standard"} sx={{ minWidth: (minWidth ?? 140) * (ratio ? ratio : 1)}}>
            {title && <InputLabel sx={{ fontSize:isMobile ? '14px' : '16px' }}>{title}</InputLabel>}
            <Select value={values && values.indexOf(sort_value) !== -1 ? labels[values.indexOf(sort_value)] : sort_value} onChange={(e) => {
                if(onSelect){
                    onSelect(values ? values[labels.indexOf(e.target.value)] : e.target.value)
                }
                if(values){
                    if(setSortValue) setSortValue(values[labels.indexOf(e.target.value)])
                }else if(ids){
                    if(setSortValue) setSortValue(ids[labels.indexOf(e.target.value)])
                }else{
                    if(setSortValue) setSortValue(e.target.value)
                }
            }} sx={{fontSize: 14 * (ratio ? ratio : 1) +"px", fontWeight: bold ? "bold" : "medium", textDecoration: 'none' }}
                MenuProps={menuProps}
            >
                {[...(default_lables ?? []), ...labels].map((label:string, index) => {
                    return (
                        <MenuItem value={label} sx={{  fontSize:14 * (ratio ? ratio : 1) +"px", padding: 5 * (ratio ? ratio : 1) + "px" + 25 * (ratio ? ratio*2 : 2) + "px", minHeight: 32 * (ratio ? ratio : 1) + "px"}}>{label}</MenuItem>
                    )
                })}
            </Select>
        </FormControl>
    )
    
}

export default FlexSortSelector;
