import React, { useContext, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Stack, Typography } from '@mui/material';
import { MyContext } from '../../../router/router';
import backgroundImage from '../../../img/background.png';
import TitleColumn from '../../Title/Column';
import { BaseButton, BaseTextField, MailTextField, PhoneTextField } from "reidea-common";
import dayjs, { Dayjs } from 'dayjs';
import axios from "axios";
import { UseQueryResult } from 'react-query';
import { useGetCompanies } from '../../API/companies';
import { simple } from '../../../interface/company';
import FlexSortSelector from '../../Select/flexSort';
import { usePutProject } from '../../API/projects';

const AddProject: React.FC = () => {
    const { subTitleFontSize, mainFontSize, contentRatio, isXS, isSmall, headerSize } = useContext(MyContext);
    const [name, setName] = useState('');

    const { data: companies, queryKey:companies_queryKey, isLoading: isLoading_companies } : UseQueryResult<any[], Error> & { queryKey: string } = useGetCompanies("companies", null);

    const _isMobile = isXS || isSmall;
    const stackRef = useRef<HTMLDivElement>(null);  
    const [height, setHeight] = useState<number>(0)
    useLayoutEffect(()=>{
        if(stackRef.current) setHeight(stackRef.current.offsetHeight)
    }, [stackRef.current])


    const [company_id, setCompanyID] = React.useState<string>('');

    const options = companies?.map((company:simple) => (
        {value: company.company_id, label: company.name}
    )) ?? []

    const putMutation = usePutProject();
    const onSend = async () => {
        try {
            await putMutation.mutate({
                newValues: {
                    company_id: company_id,
                    name:name,
                },
            },
            {onSuccess: () => {window.location.reload()}});
        } catch (error) {
            console.error('Failed to update like status:', error);
        }
    }

    const [isFormValid, setIsFormValid] = useState<boolean>(false);
    const checkFormValidity = (): void => {
        const isValid = !!(
            name && name.trim() !== '' && company_id
        );
        setIsFormValid(isValid);
    };
    useEffect(() => {
        checkFormValidity();
    }, [name, company_id]);

    return (
        <Stack id={"add-project"} position={"relative"} direction={"column"} width={"100%"} minWidth={"100%"} alignItems={"center"} mt={headerSize+20+"px"} mb={headerSize+"px"}>
            <Stack component="img" position={"absolute"} zIndex={0} top={0} src={backgroundImage} width={"100%"} height={"100%"} sx={{ opacity: 0.2, objectFit: "cover", color: "white" }} />

            <TitleColumn title='新規プロジェクトの追加' title_en='ADD-PROJECT' fontSize={subTitleFontSize + 5} />
            <Stack zIndex={2} mt={4} width={isXS ? "90%" : `${contentRatio * 100 - 25}%`} justifyContent={"center"} spacing={2}>
                <Stack direction={"row"} width={"100%"} justifyContent={"space-between"}>
                    <Stack width={"28%"} justifyContent={"space-between"}>
                        <Typography sx={{ fontSize: mainFontSize, color: "#202020" }}>会社選択 *</Typography>
                        <FlexSortSelector labels={options.map((option:any) => option.label)} sort_value={company_id}
                            values={options.map((option:any) => option.value)} borderRadius={1} setSortValue={setCompanyID}
                        />
                    </Stack>
                    <Stack width={"59%"}>
                        <Typography sx={{ fontSize: mainFontSize, color: "#202020" }}>プロジェクト名 *</Typography>
                        <Stack ref={stackRef}>
                            <BaseTextField border='1px solid lightgrey' borderRadius={2} placeholder='⚪︎⚪︎プロジェクト' value={name} setValue={setName} hovered_backgroundColor='#F5FBFE' backgroundColor='#F5FBFE' />
                        </Stack>
                    </Stack>
                    <Stack width={"10%"} justifyContent={"flex-end"}>
                        <BaseButton disabled={!isFormValid} onClick={()=>{onSend()}} height={height+"px"} text='追加' fontSize={mainFontSize} />
                    </Stack>
                </Stack>
            </Stack>

        </Stack>
    );
};

export default AddProject;