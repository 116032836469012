import React from 'react'
import { Stack, Box } from '@mui/material';
import AddCompany from '../components/Main/AddCompany';
import Companies from '../components/Main/Companies';


interface Props {
  isMobile : boolean;
}


const CompaniesPage = ({isMobile}: Props) => {

  return (
    <Stack alignItems={'center'} bgcolor={'white'} position={'relative'} spacing={0}>
        {/* メインコンポーネントをここに記述 */}
        <AddCompany/>
        <Companies/>
       
    </Stack>
  )
}

export default CompaniesPage;
