import React, { useContext, useLayoutEffect, useRef, useState } from 'react';
import { Box, Button, Stack, Typography } from '@mui/material';
import { MyContext } from '../../../router/router';
import backgroundImage from  '../../../img/background.png'
import TitleColumn from '../../Title/Column';
import {BaseButton, BaseGrid, ObserverStack} from "reidea-common"
import { UseQueryResult } from 'react-query';
import { useGetProjects } from '../../API/projects';
import { simple } from '../../../interface/project';


const About: React.FC = () => {

    // Mycontextからプログラム全体で共有する変数を持ってこれる。
    const { subTitleFontSize, contentRatio, isXS, isSmall} = useContext(MyContext);

    // モバイル判定
    const _isMobile = (isXS || isSmall)

    // コンポーネントが画面内に入ったらisViewedがtrueになる。isOneTime={true}となっているときは、trueになったっきりで戻らない。
    const [isViewed, setIsViewed] = useState<boolean>(false)

    const buttonHeight = (subTitleFontSize-4)*2.2+'px'
    const buttonFontSize = subTitleFontSize-8;

    const onClick = (id:string) => {
        window.location.href = "/project?projectID="+id
    }

    const { data: projects, queryKey:projects_queryKey, isLoading: isLoading_projects } : UseQueryResult<any[], Error> & { queryKey: string } = useGetProjects("projects", null);

    return (
        <Stack id={"about"} position={"relative"} direction={"column"} width={"100%"} minWidth={"100%"} bgcolor={"#F5FBFE"} alignItems={"center"} py={6} pb={26}>
            <Stack component="img" position={"absolute"} zIndex={0} top={0} src={backgroundImage} width={"100%"} height={"100%"} sx={{ opacity: 0.8, objectFit:"cover", color:"white"}}/>

            <TitleColumn title='プロジェクト一覧' title_en='PROJECTS' fontSize={subTitleFontSize+5}/>
            <Stack width={contentRatio*100+"%"} direction={"row"} mt={3} alignItems={"flex-start"} justifyContent={"space-between"} >
                <ObserverStack alignItems={"center"} width={"49%"} isOneTime={true} setObserved={setIsViewed}>
                    <Typography my={1} zIndex={1} style={{color:"#383E86", fontWeight:800, fontSize:subTitleFontSize-8, letterSpacing:2}}  >
                        {"進行中"}
                    </Typography>
                    <BaseGrid enableAnimation={true} animation={isViewed} animationDuration={1} animationDelay={0.2} animationInitialDisplay={false} animationOffsetY={10} 
                        height={(subTitleFontSize-4)*3} cols={1} spacingX={1} spacingY={2} items={
                        (projects ?? []).filter((project:simple) => project.done === false).map((project:simple, index:number) => (
                            <BaseButton onClick={()=>{onClick(project.project_id)}}
                                text={project.name} height={buttonHeight} fontSize={buttonFontSize} bold />
                        ))
                    }/>
                </ObserverStack>
                <ObserverStack alignItems={"center"} width={"49%"} isOneTime={true} setObserved={setIsViewed}>
                    <Typography my={1} zIndex={1} style={{color:"#383E86", fontWeight:800, fontSize:subTitleFontSize-8, letterSpacing:2}}  >
                        {"終了済み"}
                    </Typography>
                    <BaseGrid enableAnimation={true} animation={isViewed} animationDuration={1} animationDelay={0.2} animationInitialDisplay={false} animationOffsetY={10} 
                        height={(subTitleFontSize-4)*3} cols={1} spacingX={1} spacingY={2} items={
                        (projects ?? []).filter((project:simple) => project.done === true).map((project:simple, index:number) => (
                            <BaseButton onClick={()=>{onClick(project.project_id)}}
                                color='#383E86' border='1px solid #383E86' 
                                backgroundColor='white' selectedBackGroundColor='lightgrey' text={project.name} height={buttonHeight} fontSize={buttonFontSize} bold />
                        ))
                    }/>
                </ObserverStack>
            </Stack>
        </Stack>
    );
};

export default About;