import React, { useContext, useEffect, useState } from 'react'
import { Stack, Box, Typography, Paper } from '@mui/material';
import SimpleAccordion from '../components/Accordion';
import { MyContext } from '../router/router';
import { BaseButton, BaseGrid } from 'reidea-common';
import backgroundImage from  './../img/background.png'
import TitleColumn from '../components/Title/Column';
import { UseQueryResult } from 'react-query';
import { useDeleteProject, useGetProjects, usePutProject } from '../components/API/projects';
import { useGetCompanies } from '../components/API/companies';
import { useSearchParams } from 'react-router-dom';
import { useGetSections, usePutSection } from '../components/API/sections';
import { simple as simple_project } from '../interface/project';
import { simple as simple_section } from '../interface/section';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';

interface Props {
  isMobile : boolean;
}


const Project = ({isMobile}: Props) => {
  const { subTitleFontSize, mainFontSize, contentRatio, isXS, isSmall, headerSize } = useContext(MyContext);
  
  // ルームID
  const [searchParams] = useSearchParams();
  const project_id :string | null = searchParams.get(`projectID`) ?? null

  const { data: projects, queryKey:projects_queryKey, isLoading: isLoading_projects } : UseQueryResult<simple_project[], Error> & { queryKey: string } = useGetProjects("projects", project_id);
  const { data: sections, queryKey:sections_queryKey, isLoading: isLoading_sections } : UseQueryResult<simple_section[], Error> & { queryKey: string } = useGetSections("sections", "project_id="+project_id);
  const [company_name, setCompanyName] = useState<string>("")
  const [project, setProject] = useState<simple_project | null>(null)
  useEffect(()=>{
    if(projects && projects.length === 1){
      setCompanyName(projects[0].company.name)
      setProject(projects[0])
    }
  },[projects])

  const putMutation = usePutSection();
  const putMutationProject = usePutProject();
  const deleteMutationProject = useDeleteProject();
  const onCreate = async () => {
    try {
      if(project_id){
        await putMutation.mutate({
            newValues: {
              project_id: project_id,
              text: "",
              title: ""
            },
        },
        {onSuccess: () => {window.location.reload()}});
      }
    } catch (error) {
        console.error('Failed to update like status:', error);
    }
  }
  const onDelete = () => {
    if(project_id){
      deleteMutationProject.mutate(project_id,{onSuccess:async()=>{
        window.location.href = "/projects"
      }})
    }
  }

  return (
    <Stack bgcolor={"snow"} mt={headerSize+"px"} zIndex={2} minHeight={1000} pt={2} pb={5} alignItems={'center'} position={'relative'} spacing={4}>

        <Stack alignItems={"center"} width={contentRatio*100+"%"} zIndex={2} direction={"row"} justifyContent={"space-between"}>
          <Stack onClick={()=>{
            window.location.href="/projects"
          }} sx={{cursor:"pointer"}} spacing={1} direction={"row"} alignItems={"center"}>
            <NavigateBeforeIcon sx={{color:"#383E86"}} />
            <Typography color={"#383E86"} fontWeight={600} fontSize={mainFontSize} letterSpacing={1.2} >
                {"プロジェクト一覧"}
            </Typography>
          </Stack>
          <BaseButton height='30px' px={3} color='red' backgroundColor='white' border='1px solid red' text='このプロジェクトを削除'
            onClick={()=>{onDelete()}}
          />
        </Stack>


        {/* メインコンポーネントをここに記述 */}
        <Stack spacing={2} boxShadow={'0 6px 8px rgba(0, 0, 0, 0.15)'} bgcolor={"white"} pt={4} px={6} pb={10} width={contentRatio*100+"%"} alignItems={"center"} >
          <TitleColumn title={company_name+'様'} title_en={project?.name ?? "PROJECT"} fontSize={subTitleFontSize+5}/>
              
          <Stack bgcolor={"white"} width={100+"%"} alignItems={"center"} pb={5}>
            <BaseGrid cols={1} items={(sections ?? []).map((section:simple_section) => (
              <SimpleAccordion section_id={section.section_id} title={section.title} text={section.text}/>
            ))}/>
          </Stack>

          <BaseButton color='#383E86' backgroundColor='white' border='1px solid #383E86'
            text='+ 新しいセクションを追加' height='50px' width='40%' bold fontSize={mainFontSize} 
            onClick={()=>{onCreate()}}
          />
          <BaseButton 
            text={project?.done === true ? 'プロジェクトを進行中に戻す' : "プロジェクトを終了"} height='50px' width='40%' bold fontSize={mainFontSize} 
            onClick={async ()=>{
              if(project){
                await putMutationProject.mutate({
                  newValues:{project_id:project_id ?? "", company_id:project.company_id, done: project?.done === true ? 0 : 1}
                },{onSuccess: ()=>{window.location.href = "/projects"}})
              }
            }}
          />
        </Stack>

       
    </Stack>
  )
}

export default Project;
