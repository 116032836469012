import React from 'react'
import { Stack, Box } from '@mui/material';
import Header from '../components/Main/Header';
import TitleBanner from '../components/Main/HeadBanner';
import Projects from '../components/Main/Projects';
import WhatsCF from '../components/Main/WhatsCF';
import Merit from '../components/Main/Merit';
import Service from '../components/Main/Service';
import Flow from '../components/Main/Flow';
import ContactBanner from '../components/Main/ContactBanner';
import Amount from '../components/Main/Amount';
import Works from '../components/Main/Works';
import Contact from '../components/Main/AddProject';
import Footer from '../components/Main/Footer';
import AddCompany from '../components/Main/AddCompany';
import { UseQueryResult } from 'react-query';
import { useGetProjects } from '../components/API/projects';


interface Props {
  isMobile : boolean;
}


const HomePage = ({isMobile}: Props) => {

  return (
    <Stack alignItems={'center'} bgcolor={'white'} position={'relative'} spacing={0}>
        {/* メインコンポーネントをここに記述 */}
        <Contact/>
        <Projects/>
       
    </Stack>
  )
}

export default HomePage;
