import React, { useContext, useEffect, useState } from 'react';
import { Stack, Typography } from '@mui/material';
import { MyContext } from '../../../router/router';
import backgroundImage from '../../../img/background.png';
import TitleColumn from '../../Title/Column';
import { BaseButton, BaseTextField, MailTextField, PhoneTextField } from "reidea-common";
import dayjs, { Dayjs } from 'dayjs';
import axios from "axios";
import { usePutCompany } from '../../API/companies';
import { QueryClient } from 'react-query';

const AddCompany: React.FC = () => {
    const { subTitleFontSize, mainFontSize, contentRatio, isXS, isSmall, headerSize } = useContext(MyContext);
    const [name, setName] = useState('');
    const [manager_name, setManagerName] = useState('');
    const [phone, setPhone] = useState('');
    const [phoneError, setPhoneError] = useState<boolean>(false);
    const [mail, setMail] = useState('');
    const [mailError, setMailError] = useState<boolean>(false);
    const [isFormValid, setIsFormValid] = useState<boolean>(false);

    const putMutation = usePutCompany();
    const checkFormValidity = (): void => {
        const isValid = !!(
            name && name.trim() !== '' &&
            manager_name && manager_name.trim() !== '' &&
            mail && !mailError && phone && !phoneError
        );
        setIsFormValid(isValid);
    };

    useEffect(() => {
        checkFormValidity();
    }, [name, phone, mail, manager_name]);

    const onSend = async () => {
        try {
            await putMutation.mutate({
                newValues: {
                    name:name,
                    manager_name: manager_name,
                    phone: phone,
                    mail: mail
                },
            },
            {onSuccess: () => {window.location.reload()}});
        } catch (error) {
            console.error('Failed to update like status:', error);
        }
    }

    const _isMobile = isXS || isSmall;

    return (
        <Stack id={"add-project"} position={"relative"} direction={"column"} width={"100%"} minWidth={"100%"} alignItems={"center"} py={5} mt={headerSize+"px"}>
            <Stack component="img" position={"absolute"} zIndex={0} top={0} src={backgroundImage} width={"100%"} height={"100%"} sx={{ opacity: 0.2, objectFit: "cover", color: "white" }} />

            <TitleColumn title='新規会社の追加' title_en='ADD-COMPANY' fontSize={subTitleFontSize + 5} />
            <Stack zIndex={2} alignItems={"center"} mt={4} width={isXS ? "90%" : `${contentRatio * 100 - 25}%`} justifyContent={"center"} spacing={2}>
                <Stack direction={"row"} width={"100%"} justifyContent={"space-between"}>
                    <Stack width={"49%"}>
                        <Typography sx={{ fontSize: mainFontSize, color: "#202020" }}>会社名 *</Typography>
                        <BaseTextField border='1px solid lightgrey' borderRadius={2} placeholder='⚪︎⚪︎株式会社' value={name} setValue={setName} hovered_backgroundColor='#F5FBFE' backgroundColor='#F5FBFE' />
                    </Stack>
                    <Stack width={"49%"}>
                        <Typography sx={{ fontSize: mainFontSize, color: "#202020" }}>担当者名 *</Typography>
                        <BaseTextField border='1px solid lightgrey' borderRadius={2} placeholder='中井 涼祐' value={manager_name} setValue={setManagerName} hovered_backgroundColor='#F5FBFE' backgroundColor='#F5FBFE' />
                    </Stack>
                </Stack>
                <Stack pb={2} direction={"row"} width={"100%"} justifyContent={"space-between"}>
                    <Stack width={"49%"}>
                        <Typography sx={{ fontSize: mainFontSize, color: "#202020" }}>電話番号 *</Typography>
                        <PhoneTextField border='1px solid lightgrey' borderRadius={2} placeholder='000-0000-0000' error={phoneError} setError={setPhoneError} value={phone} setValue={setPhone} hovered_backgroundColor='#F5FBFE' backgroundColor='#F5FBFE' />
                    </Stack>
                    <Stack width={"49%"}>
                        <Typography sx={{ fontSize: mainFontSize, color: "#202020" }}>メールアドレス *</Typography>
                        <MailTextField border='1px solid lightgrey' borderRadius={2} placeholder='example@gmail.com' error={mailError} setError={setMailError} value={mail} setValue={setMail} hovered_backgroundColor='#F5FBFE' backgroundColor='#F5FBFE' />
                    </Stack>
                </Stack>
                <BaseButton onClick={()=>{onSend()}} disabled={!isFormValid} width='60%' bold height={40+"px"} text='追加' fontSize={mainFontSize} />
            </Stack>

        </Stack>
    );
};

export default AddCompany;