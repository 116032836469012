import React, { useContext, useState } from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Button, Stack } from '@mui/material';
import { MyContext } from '../../router/router';
import { BaseButton, BaseTextField } from 'reidea-common';
import { useDeleteSection, usePutSection } from '../API/sections';

interface Props {
    title:string;
    text:string;
    section_id:string;
}


const SimpleAccordion: React.FC<Props> = (props) => {
  const { subTitleFontSize, mainFontSize, contentRatio, isXS, isSmall, headerSize } = useContext(MyContext);
    
  const [expanded, setExpanded] = useState<boolean>(false);
  const [title, setTitle] = useState<string>(props.title);
  const [text, setText] = useState<string>(props.text);
  const [edit, setEdit] = useState<boolean>(false);

  const putMutation = usePutSection();
  const deleteSectionMutation = useDeleteSection()
  const handleAccordionChange = (event:any, isExpanded:boolean) => {
    setExpanded(isExpanded);
  };

  const onSave = async () => {
    try {
      await putMutation.mutate({
          newValues: {
            section_id:props.section_id,
            title: title,
            text: text
          },
      },
      {onSuccess: () => {window.location.reload()}});
    } catch (error) {
        console.error('Failed to update like status:', error);
    }
  }

  const onDelete = () => {
    deleteSectionMutation.mutate(props.section_id,{onSuccess:async()=>{
      window.location.reload()
  }})
  }

  return (
    <Stack width={"100%"} >
      <Accordion expanded={expanded} onChange={handleAccordionChange} >
        <AccordionSummary
          sx={{backgroundColor: "#383E86",
            '& .MuiAccordionSummary-root': {
              "&.Mui-focused": {
                backgroundColor: "#383E86",
              },
            }
          }}
        >
            <Stack direction={"row"} width={"100%"} alignItems={"center"} justifyContent={"space-between"}>
                {!edit ? 
                  <Typography color={"white"} fontWeight={600}  fontSize={mainFontSize} letterSpacing={1.2} >
                      {title.trim() === "" ? "タイトルがありません" : title}
                  </Typography>
                :
                  <Stack onClick={(e:React.MouseEvent<HTMLDivElement>)=>{
                    e.stopPropagation();
                  }}>
                      <BaseTextField padding='0px' borderRadius={0} backgroundColor='transparent' hovered_backgroundColor='transparent' borderBottom='1px solid white' 
                        color={"white"} bold value={title} setValue={setTitle} placeholder='タイトルを入力' />
                  </Stack>
                }

                {!edit &&
                    <Button sx={{color:"white", fontWeight:"bold"}} onClick={(e:React.MouseEvent<HTMLButtonElement>)=>{
                        e.stopPropagation();
                        setExpanded(true);
                        setEdit(true);
                    }} > 編集する </Button>
                }
                {edit &&
                    <Stack direction={"row"} spacing={1}>
                        <Button sx={{color:"white", bgcolor:"transparent", fontWeight:"bold", ":hover":{bgcolor:"transparent", opacity:0.8}}} onClick={(e:React.MouseEvent<HTMLButtonElement>)=>{
                            e.stopPropagation(); setEdit(false);}}> キャンセル </Button>
                        <Button sx={{color:"#383E86", bgcolor: "white", ":hover":{bgcolor:"white", opacity:0.8}, fontWeight:"bold"}} onClick={(e:React.MouseEvent<HTMLButtonElement>)=>{
                            e.stopPropagation(); setEdit(true); onSave();}}> 保存する </Button>
                        <Button sx={{color:"white", bgcolor:"#FE595B", fontWeight:"bold", ":hover":{bgcolor:"#FE595B", opacity:0.8}}} onClick={(e:React.MouseEvent<HTMLButtonElement>)=>{
                            e.stopPropagation(); setEdit(true); onDelete();}}> 削除する </Button>
                    </Stack>
                }
            </Stack>
        </AccordionSummary>
        <AccordionDetails sx={{bgcolor:'white'}}>
          <BaseTextField padding='0px' backgroundColor='transparent' hovered_backgroundColor='transparent' 
            placeholder='' color='#363636' fontSize={16} readOnly={!edit} value={text} setValue={setText} rows={8} maxRows={8}/>
        </AccordionDetails>
      </Accordion>
    </Stack>
  );
};

export default SimpleAccordion;